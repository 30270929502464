import http from "@/plugins/http/http";
import auth from "@/util/loginUtil";
import mqtt from "mqtt";

export default class MqttClient {
  constructor() {
    console.log("---new mqtt client---");
  }

  static checkClientStatus(sn) {
    let clientId = "GID_tsiot@@@" + "d_" + sn;
    return http.get("mqtt/clientStatus", {clientId});
  }

  connect(callback) {
    let host;
    const  url=     window.document.location.href;
    if (url.indexOf("https")>-1) {
      host =  window.config.wssmqttUrl;
    }else {
      host = window.config.mqttUrl;
    }

    let clientId = `gr_js${auth.getUserId()}_${Math.random().toString(16).substr(2, 6)}`;
    this.client = mqtt.connect(host, {
      clientId: clientId,
      username: auth.getUsername(),
      password: auth.getToken(),
      clean: true,
    });
    // console.log(this.client,25);

    this.client.on("connect", () => {
      callback.onConnect(this.client);
    });

    this.client.on("message", (topic, message) => {
      console.log("---mqtt收到消息---", topic, message.toString());
      callback.onMessage(topic, JSON.parse(message.toString()));
    });

    this.client.on("packetsend", (packet) => {
      if (packet.cmd!== "publish") {
        console.log("---mqtt发送消息---", packet);

      }
    });

    this.client.on("error", (error) => {
      console.log("---mqtt出错---", error);
      callback.onError("出错:" + error.message);
    });

    this.client.on("reconnect", () => {
      callback.onError("重连中...");
    });
  }
}
